import { WSInput } from '@knitiv/api-client-javascript'
import Peer from 'simple-peer'

export type IntegrationProgressTypes = 'init' | 'start' | 'progress' | 'end'
export type ScreenShareTypes = 'peer-exchange' | 'ask-auth' | 'auth-denied' | 'auth-accepted'

// -----------------------------------------------------------------------------

export interface BaseIntegrationProgressEvent {
  data: {
    processKid: string;
    sourceKid: string;
    line: string;
    class: string;
    level: string;
    message: string;
  }
}

/** Integration */
export type IntegrationInitEvent = WSInput<IntegrationProgressTypes, 'init', BaseIntegrationProgressEvent>
export type IntegrationStartEvent = WSInput<IntegrationProgressTypes, 'start', BaseIntegrationProgressEvent>
export type IntegrationProgressEvent = WSInput<IntegrationProgressTypes, 'progress', BaseIntegrationProgressEvent>
export type IntegrationEndEvent = WSInput<IntegrationProgressTypes, 'end', BaseIntegrationProgressEvent>

export const isIntegrationInitEvent = (
  variableToCheck: WSInput<IntegrationProgressTypes>,
): variableToCheck is IntegrationInitEvent => variableToCheck.payload.type === 'init'

export const isIntegrationStartEvent = (
  variableToCheck: WSInput<IntegrationProgressTypes>,
): variableToCheck is IntegrationStartEvent => variableToCheck.payload.type === 'start'

export const isIntegrationProgressEvent = (
  variableToCheck: WSInput<IntegrationProgressTypes>
): variableToCheck is IntegrationProgressEvent => variableToCheck.payload.type === 'progress'

export const isIntegrationEndEvent = (
  variableToCheck: WSInput<IntegrationProgressTypes>,
): variableToCheck is IntegrationEndEvent => variableToCheck.payload.type === 'end'

/** Screenshare */
export type PeerExchangeEvent = WSInput<ScreenShareTypes, 'peer-exchange', {
  data: Peer.SignalData;
  recipient: 'client' | 'initiator'
}>

export type AskAuthEvent = WSInput<ScreenShareTypes, 'ask-auth', unknown>
export type AuthAcceptedEvent = WSInput<ScreenShareTypes, 'auth-accepted', unknown>
export type AuthDeniedEvent = WSInput<ScreenShareTypes, 'auth-denied', unknown>

export const isPeerExchangeEvent = (
  variableToCheck: WSInput<ScreenShareTypes>,
): variableToCheck is PeerExchangeEvent => variableToCheck.payload.type === 'peer-exchange'

export const isAskAuthEvent = (
  variableToCheck: WSInput<ScreenShareTypes>,
): variableToCheck is AskAuthEvent => variableToCheck.payload.type === 'ask-auth'

export const isAuthAcceptedEvent = (
  variableToCheck: WSInput<ScreenShareTypes>,
): variableToCheck is AuthAcceptedEvent => variableToCheck.payload.type === 'auth-accepted'

export const isAuthDeniedEvent = (
  variableToCheck: WSInput<ScreenShareTypes>,
): variableToCheck is AuthDeniedEvent => variableToCheck.payload.type === 'auth-denied'

/** */
