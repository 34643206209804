

































































































































import copy from 'copy-to-clipboard'
import Peer from 'simple-peer'
import Vue from 'vue'
import { Room } from '@knitiv/api-client-javascript'
import {
  AskAuthEvent,
  AuthAcceptedEvent,
  isAskAuthEvent,
  isAuthAcceptedEvent,
  isAuthDeniedEvent,
  isPeerExchangeEvent,
  PeerExchangeEvent,
  ScreenShareTypes,
} from '../importfile/dashboard/integrations/models/events'
import { ws } from '@/utils/api'
import Page from '@/components/layouts/page.vue'
import { WithRefs } from '@/models/vue'
// import PasswordTextField from '@/components/knitiv/password-text-field.vue'

type Refs = {
  video: HTMLVideoElement
}

interface Participant {
  label: string;
  kid: string;
  id: string;
}

export default (Vue as WithRefs<Refs>).extend({
  name: 'Support',

  components: {
    Page,
    // PasswordTextField
  },
  data() {
    return {
      peer: null as null | Peer.Instance,
      id: '',
      isInitiator: false,
      participants: [] as Participant[],
      sharedRoom: '',
      streamIsRunning: false,
      stream: null as null | MediaStream,
      chatRoom: null as null | Room<ScreenShareTypes>,
      // Initiator
      displayToSelf: true,
      password: '',
      // Client
      roomPassword: '',
      isAuthorized: false
    }
  },
  computed: {
    finalParticipants(): Participant[] {
      // add yourself as a participant
      const result = {
        kid: this.$accessor.user?.profil.kid ?? '<not found>',
        label: 'Vous',
        id: this.id,
      }
      return [
        result,
        ...this.participants,
      ]
    },
  },
  async beforeDestroy() {
    if (this.chatRoom) {
      this.chatRoom.unsubscribe()
    }

    const manager = await ws()

    manager.destroy(1000, 'Closed by user')
  },
  async mounted() {
    this.id = this.$route.params.id
    this.isInitiator = this.$route.query.initiator === 'true'
    this.sharedRoom = `/screenshare/${this.id}`

    const manager = await ws()

    this.chatRoom = manager.createRoom<ScreenShareTypes>(this.sharedRoom)

    this.chatRoom
      .onJoined((message) => {
        console.log('joined', message)
        const participantIndex = this.participants.findIndex((p) => p.id === message.id)
        // If not found, add it, otherwise, the user is already inside
        if (participantIndex === -1) {
          this.participants.push({
            kid: message.profile,
            label: message.profile,
            id: message.id,
          })
        }
        console.log('this.participants', this.participants)
      })

      .onLeaved((message) => {
        console.log('leaved', message)
        console.log('this.participants', this.participants)
        const participantIndex = this.participants.findIndex((p) => p.id === message.id)
        if (participantIndex >= 0) {
          this.participants.splice(participantIndex, 1)
        }
      })

      .onList((message) => {
        console.log('list', message)
        this.$set(this, 'participants', message.profiles.map((profile) => {
          const participant: Participant = {
            kid: profile.profile,
            label: profile.profile,
            id: profile.id,
          }
          return participant
        }))
      })

      .onMessage(async (message) => {
        console.log('onMessage', message)
        if (isPeerExchangeEvent(message)) {
          if (this.peer) {
            if (this.isInitiator && message.payload.recipient === 'initiator') {
              this.peer.signal(message.payload.data)
            } else if (!this.isInitiator && message.payload.recipient === 'client') {
              this.peer.signal(message.payload.data)
            }
          } else {
            console.error('no peer')
          }
        } else if (isAskAuthEvent(message) && this.isInitiator) {
          console.log('this.participants', this.participants)
          console.log('message.from', message.from)
          const client = this.participants.find(p => p.id === message.from)
          if (client) {
            await this.$accessor.representations.fetch(client.kid)
            const repre = this.$accessor.representations.get(client.kid)
            const answer = await this.$dialog.confirm(
              "Connection au salon",
              `"${repre}" aimerais l'autorisation de voir votre écran`,
              {
                accept: {
                  label: "Accorder",
                  props: {
                    color: 'success',
                  },
                },
                deny: {
                  label: "Refuser",
                  props: {
                    color: 'error',
                  },
                }
              }
            )

            if (answer === 'accept') {
              message.reply<AuthAcceptedEvent>({
                type: 'auth-accepted'
              })
            }
          } else {
            console.log('client not found')
          }
        } else if (isAuthAcceptedEvent(message) && !this.isInitiator) {
          this.isAuthorized = true
        } else if (isAuthDeniedEvent(message) && !this.isInitiator) {
          this.isAuthorized = false
          await this.$dialog.alert(
            "Connection au salon",
            `La personne a refusée votre demande`
          )
        }
      })
      .subscribe()

    if (!this.isInitiator) {
      this.peer = new Peer()

      this.peer.on('signal', (data: any) => {
        this.chatRoom?.send<PeerExchangeEvent>({
          recipient: 'initiator',
          type: 'peer-exchange',
          data,
        })
      })

      this.peer.on('stream', (stream) => {
        const { video } = this.$refs
        video.srcObject = stream
        video.play()
      })
    }
  },
  methods: {
    async askForAuth() {
      this.chatRoom?.send<AskAuthEvent>({
        type: 'ask-auth',
      })
    },
    copyRoom() {
      copy(window.location.origin + this.$route.path)
      this.$toast.success('Lien de la salle copié')
    },
    async startVideoChat() {
      this.stream = await navigator.mediaDevices.getDisplayMedia(
        {
          video: true,
          audio: true,
        },
      )

      this.streamIsRunning = true

      this.peer = new Peer({
        initiator: true,
        stream: this.stream,
      })

      this.peer.on('signal', (data: any) => {
        this.chatRoom?.send<PeerExchangeEvent>({
          recipient: 'client',
          type: 'peer-exchange',
          data,
        })
      })

      this.peer.on('error',  err => console.log('error', err))

      this.peer.on('close', () => {
        console.log('peer closed')
      })

      this.peer.on('end', () => {
        console.log('peer ended')
      })

      // if (this.displayToSelf) {
      //   const video = this.$refs.video
      //   video.srcObject = stream
      //   video.play()
      // }

      this.stream.getTracks().forEach((track) => {
        track.addEventListener('ended', (event) => {
          console.log('track ended', event)
          this.peer?.destroy()
          this.streamIsRunning = false
        })
      })
    },
    stopVideoChat() {
      if (this.stream === null) {
        console.log('stream is not running')
        return
      }

      this.stream.getTracks().forEach((track) => {
        track.stop()
      })

      this.streamIsRunning = false
    },
  },
})
